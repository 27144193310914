import React from 'react'
import { useSelector } from 'react-redux'
import { useDropOffDeliveryMutation } from '../../services/shipmentApi'
import RatingShipment from '../../components/Shipments/Rating'

function EndShipment({ shipment }) {
  let currentUser = useSelector((state) => state.user.currentUser)
  // let currentDeliver = useSelector((state) => state.user.currentDeliver)

  const [dropOffDelivery, result] = useDropOffDeliveryMutation()
  const [rating, setRating] = React.useState(false)

  const dropOffOrder = async (shipment) => {
    await dropOffDelivery(shipment)
  }

  return (
    <div className="">
      {/* Bouton pour le client pour noter la livraison */}
      {shipment?.order?.customer?._id === currentUser?._id && (
        <>
          {!shipment?.isRated && shipment?.status === 'delivered' && (
            <div className="p-4 bg-white rounded-lg shadow-lg">
              <button
                className="border border-blue-500 text-blue-500 font-medium rounded-md px-4 py-2 hover:bg-blue-500 hover:text-white transition-all duration-300 ease-in-out"
                onClick={(e) => {
                  e.preventDefault()
                  setRating(true)
                }}
              >
                Noter la livraison
              </button>
            </div>
          )}
        </>
      )}

      {/* Bouton pour le livreur pour valider la livraison */}
      {shipment?.order?.customer?._id === currentUser?._id && (
        <div className="p-4 bg-white rounded-lg shadow-lg">
          {!shipment?.isDropOff && (
            <button
              className="bg-green-500 flex items-center gap-2 px-4 py-2 text-white font-medium rounded-md shadow-md hover:bg-blue-700 transition-all duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
              onClick={(e) => {
                e.preventDefault()
                dropOffOrder(shipment._id)
              }}
            >
              {result?.isLoading
                ? 'Validation du dépôt...'
                : 'Valider le depôt'}
            </button>
          )}
        </div>
      )}

      {/* Modal de notation */}
      <RatingShipment openModal={rating} setOpenModal={setRating} />
    </div>
  )
}

export default EndShipment
