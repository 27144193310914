import React, { useState } from 'react'
import { TextInput, Label, Textarea, Checkbox } from 'flowbite-react'
import AddImage from '../AddImage'
import { useCreateShopMutation } from '../../services/shopApi'
import { useSelector, useDispatch } from 'react-redux'
import { ModalLocation } from '../location/ModalLocation'
import { setLocation, setMyShop } from '../../features/user/user'
// import AddressName from '../location/AddressName'
import DisplayAddress from '../location/DisplayAddress'
import { IoCreateOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import LegalReference from '../../pages/shops/LegalReference'

export default function MainForm({ pricing }) {
  const navigate = useNavigate()
  console.log('navigate : ', navigate)
  const [createShop, { isLoading }] = useCreateShopMutation()
  const [nextRef, setNextRef] = React.useState(false)

  const dispatch = useDispatch()
  const location = useSelector((state) => state.user.location)
  const currentUser = useSelector((state) => state.user.currentUser)

  const phoneRegex = /^0(8[1-5|8-9]|9[0-5|7-9])[0-9]{7}$/

  const [image, setImage] = useState(null)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [phone, setPhone] = useState('')
  const [isCorporate, setIsCorporate] = useState(false)
  const [openMap, setOpenMap] = useState(false)
  const [errors, setErrors] = useState({})

  const [idnat, setIdnat] = useState(null)
  const [rccm, setRccm] = useState(null)
  const [nif, setNif] = useState(null)

  const [isidnat, setIsIdnat] = useState(true)
  const [isrccm, setIsRccm] = useState(true)
  const [isnif, setIsNif] = useState(true)

  const validateIDNAT = (idnat) => /^[0-9]{9}[A-Z]{2}$/.test(idnat)
  const validRccm = (code) => /^CD\/[A-Z]{3}\/\d{4}\/[A-Z]{1}\d{3,}$/.test(code)
  const validNIF = (nif) => /^CD\/\d{9}\/[A-Z]$/.test(nif)

  const modifyPhone = (phone) => {
    return '243' + phone.slice(1)
  }

  const validateForm = () => {
    const newErrors = {}

    if (!name) {
      newErrors.name = "Le nom de l'enseigne est obligatoire"
    }

    if (!phone) {
      newErrors.phone = 'Le numéro de téléphone est obligatoire'
    } else if (!phoneRegex.test(phone)) {
      newErrors.phone =
        'Le numéro de téléphone doit être au format 082XXXXXXX, 083XXXXXXX, ...'
    }

    if (!description) {
      newErrors.description = "La description de l'enseigne est obligatoire"
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0 // Return true if no errors
  }

  const validateFormLegalRef = () => {
    const newErrors = {}

    if (!idnat) {
      newErrors.idnat = 'Ce champs est obligatoire'
    }

    if (!rccm) {
      newErrors.rccm = 'Ce champs est obligatoire'
    }

    if (!nif) {
      newErrors.nif = 'Ce champs est obligatoire'
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0 // Return true if no errors
  }

  const shop = {
    name: name,
    description: description,
    phone: modifyPhone(`${phone}`),
    location: location,
    logo: image,
    user: currentUser?._id,
    pricing: pricing,
  }

  const validateCode = (idnat, rccm, nif) => {
    setIsIdnat(validateIDNAT(idnat))
    setIsRccm(validRccm(rccm))
    setIsNif(validNIF(nif))
    return validateIDNAT(idnat) && validRccm(rccm) && validNIF(nif)
  }

  const handleCreate = async () => {
    if (!validateForm()) return
    const data = shop
    await createShopValidate(data)
  }

  const nextPage = () => {
    if (!validateForm()) return
    setNextRef(true)
  }

  const handleLegalRef = async () => {
    const isValid = validateCode(idnat, rccm, nif) // Mettez à jour les états
    if (!isValid) return
    if (!validateFormLegalRef()) return
    const data = {
      ...shop,
      idnat: idnat || '',
      rccm: rccm || '',
      nif: nif || '',
    }
    await createShopValidate(data)
  }

  const createShopValidate = async (data) => {
    try {
      const response = await createShop(data).unwrap() // Déclenche la mutation et récupère la réponse
      if (response) {
        dispatch(setMyShop(response)) // Met à jour le store Redux avec les données du shop
        console.log('response : ', response)
        navigate(`/shop/me/${response?._id}`, {
          state: { key: response },
        })
      }
    } catch (error) {
      console.error('Erreur lors de la création du shop : ', error) // Gestion des erreurs
    }
  }

  return !nextRef ? (
    <div className="flex flex-col gap-8 p-6 pt-40 bg-white rounded-md shadow md:pt-40 lg:pt-40 md:p-10 md:max-w-4xl md:mx-auto md:bg-white ">
      {/* Logo Upload Section */}
      <h1 className="text-3xl font-bold text-gray-900 ">
        {'Créer votre boutique'}
      </h1>
      <div className="flex flex-col gap-3 ">
        <Label
          className="font-semibold text-gray-700"
          value="Selectionnez le logo de votre boutique"
        />
        <AddImage image={image} setImage={setImage} />
      </div>

      {/* Address Section */}
      <div className="flex flex-col gap-3">
        <Label
          className="font-semibold text-gray-700"
          value="Votre adresse liée à votre localisation actuelle"
        />
        <div className="flex flex-col gap-3 md:flex-row md:items-center">
          <DisplayAddress location={location} />
          <button
            onClick={() => setOpenMap(true)}
            className="flex items-center justify-center gap-1 p-2 text-gray-700 bg-gray-100 rounded-md shadow hover:bg-gray-200"
          >
            <IoCreateOutline />
            Modifier
          </button>
        </div>
      </div>

      {/* Name Section */}
      <div className="flex flex-col gap-3">
        <Label
          className="font-semibold text-gray-700"
          htmlFor="name"
          value="Entrez le nom de votre enseigne"
        />
        <TextInput
          id="name"
          type="text"
          onChange={(e) => setName(e.target.value)}
          placeholder="Nom de l'enseigne"
          required
        />
        {errors.name && <p className="text-sm text-red-500">{errors.name}</p>}
      </div>

      {/* Phone Section */}
      <div className="flex flex-col gap-3">
        <Label
          className="font-semibold text-gray-700"
          htmlFor="phone"
          value="Entrez le numéro de téléphone de votre enseigne"
        />
        <TextInput
          id="phone"
          type="text"
          onChange={(e) => setPhone(e.target.value)}
          placeholder="0823456777"
          required
        />
        {errors.phone && <p className="text-sm text-red-500">{errors.phone}</p>}
      </div>

      {/* Description Section */}
      <div className="flex flex-col gap-3">
        <Label
          className="font-semibold text-gray-700"
          htmlFor="description"
          value="Décrivez votre enseigne"
        />
        <Textarea
          id="description"
          type="text"
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Décrivez votre enseigne. Services et autres détails"
          required
        />
        {errors.description && (
          <p className="text-sm text-red-500">{errors.description}</p>
        )}
      </div>

      <div>
        <Label
          className="font-semibold text-gray-700 "
          htmlFor="description"
          value="Etes-vous ... ?"
        />
        <div className="flex items-center gap-12 mt-2">
          <div className="flex items-center gap-2">
            <Checkbox
              id="accept"
              checked={!isCorporate ? false : true}
              onChange={() => setIsCorporate(true)}
            />
            <Label htmlFor="accept" className="flex">
              Formel
              {/* <a href="#" className="text-cyan-600 hover:underline dark:text-cyan-500">
              terms and conditions
            </a> */}
            </Label>
          </div>
          <div className="flex items-center gap-2">
            <Checkbox
              id="accept"
              checked={!isCorporate ? true : false}
              onChange={() => setIsCorporate(false)}
            />
            <Label htmlFor="accept" className="flex">
              Informel
            </Label>
          </div>
        </div>
        {errors.description && (
          <p className="text-sm text-red-500">{errors.isCorporate}</p>
        )}
      </div>

      {/* Submit Button */}
      {isCorporate ? (
        <button
          className="py-3 text-white bg-green-500 rounded-md hover:bg-green-600"
          onClick={nextPage}
          disabled={isLoading}
        >
          {isLoading ? 'Création en cours...' : 'Suivant'}
        </button>
      ) : (
        <button
          className="py-3 text-white bg-green-500 rounded-md hover:bg-green-600"
          onClick={handleCreate}
          disabled={isLoading}
        >
          {isLoading ? 'Création en cours...' : "Ajouter l'enseigne"}
        </button>
      )}

      {/* Modal for Map */}
      {openMap && (
        <ModalLocation
          openModal={openMap}
          setOpenModal={setOpenMap}
          setLocation={setLocation}
          dispatch={dispatch}
        />
      )}
    </div>
  ) : (
    <LegalReference
      setIdnat={setIdnat}
      setRccm={setRccm}
      setNif={setNif}
      handleLegalRef={handleLegalRef}
      errors={errors}
      isLoading={isLoading}
      isidnat={isidnat}
      isrccm={isrccm}
      isnif={isnif}
      validateIDNAT={validateIDNAT}
    />
  )
}
