import { Button, Modal } from 'flowbite-react'
import { useNavigate } from 'react-router-dom'
import { IoCheckmarkCircleOutline } from 'react-icons/io5'
import { useSelector } from 'react-redux'

export default function SuccessModal(openModal, setOpenModal) {
  const navigate = useNavigate()
  const currentUser = useSelector((state) => state.user.currentUser)
  return (
    <>
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center p-5">
            <IoCheckmarkCircleOutline className="mx-auto mb-4 h-24 w-24 text-green-300 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Les produits du panier ont été commandés avec succès !!
            </h3>
            <div className="flex justify-center gap-4">
              <Button
                className="bg-green-400 text-[10px]"
                onClick={() => {
                  navigate(`/orders/me/${currentUser?._id}`)
                }}
              >
                Mes commandes
              </Button>
              <Button
                className="bg-gray-400 text-[10px]"
                onClick={() => {
                  navigate('/')
                }}
              >
                Révenir à l{"'"}accueil
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
