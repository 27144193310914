import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import Nav from '../../components/layout/Nav'
// import ItemDetailCategory from '../../components/Categories/ItemDetailCategory'
import { IoArrowForward, IoArrowBack } from 'react-icons/io5'
import { arrayLoader } from '../../helpers/LoaderItems.js/itemLoader'
import { useSelector } from 'react-redux'
import { postSeen } from '../../services/seens/add-seen'
import { useGetAnnouncementsByCategoryQuery } from '../../services/announcementApi'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'
import LottieLoader from '../../components/LottieLoader'
import ItemProduct from '../../components/ItemProduct'

// Couleurs personnalisées
// const colors = {
//   primary: '#007BFF', // Bleu
//   secondary: '#FFC107', // Jaune
//   white: '#FFFFFF', // Blanc
//   grayLight: '#F8F9FA', // Gris clair pour les fonds
//   grayDark: '#6C757D' // Gris foncé pour le texte
// }

const itemsPerPage = 12

function DetailCategory() {
  const params = useParams()
  const navigate = useNavigate()

  const { data, isLoading } = useGetAnnouncementsByCategoryQuery(params.id)
  const currentUser = useSelector((state) => state.user.currentUser)

  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    setTotalPages(Math.ceil(data?.announcements?.length / itemsPerPage))
  }, [data])

  if (isLoading)
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-grayLight">
        <LottieLoader animation={indexLoader} />
        <div className="mt-4 text-lg font-medium text-grayDark">
          Chargement des annonces de la catégorie...
        </div>
      </div>
    )

  const items = data?.announcements?.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  )

  const handleAddSeen = async (announce) => {
    const data = {
      announcementId: announce._id,
      userId: currentUser?._id,
    }

    if (currentUser) {
      const seen = await postSeen(data)
      seen && navigate(`/announcements/${announce._id}/${params.id}`)
    } else {
      navigate(`/announcements/${announce._id}/${params.id}`)
    }
  }

  return (
    <div className="min-h-screen bg-white">
      <Nav />
      <div className="pt-20 lg:pt-36 lg:px-16 px-7">
        {data?.announcements && (
          <div className="flex flex-col pt-16 lg:pt-4 lg:gap-12 lg:mb-6 lg:items-center lg:flex-row md:flex-row md:gap-32 md:items-center">
            <div className="text-[#21344e] pb-2 lg:pb-0 text-md  font-bold capitalize lg:text-[18px]">
              {data?.category?.name}
            </div>
            <div
              onClick={() => navigate(`/find-product/${params.id}`)}
              className="flex items-center gap-2 font-medium cursor-pointer text-md text-primary"
            >
              Demande personnalisée
              <IoArrowForward className="text-md" />
            </div>
          </div>
        )}
        {data?.announcements?.length > 0 ? (
          <div className="pt-6 lg:pt-0">
            <div className="grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-6 md:gap-6">
              {items?.map((announce) => (
                <div key={announce._id} onClick={() => handleAddSeen(announce)}>
                  <ItemProduct data={data?.announcements} />
                </div>
              ))}
            </div>
            {!_.isEmpty(data?.announcements) && (
              <div className="flex items-center justify-center w-full gap-4 mt-6">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 0}
                  className="transition-colors text-primary hover:text-secondary"
                >
                  <IoArrowBack className="text-2xl" />
                </button>
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    className={`text-white rounded-full px-4 py-2 text-lg ${
                      currentPage === index ? 'bg-secondary' : 'bg-primary'
                    }`}
                    key={index}
                    onClick={() => handlePageChange(index)}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages - 1}
                  className="transition-colors text-primary hover:text-secondary"
                >
                  <IoArrowForward className="text-2xl" />
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="pt-6">
            <div className="w-40 h-6 md:w-48 bg-grayLight animated-background"></div>
            <div className="grid grid-cols-2 gap-4 mt-4 md:grid-cols-4 md:gap-6">
              {arrayLoader.map((index) => (
                <div
                  key={index}
                  className="flex flex-col my-3 border border-gray-200 rounded-lg shadow-lg bg-grayLight"
                >
                  <div className="w-full h-40 bg-gray-200 animated-background"></div>
                  <div className="p-4">
                    <div className="w-32 h-4 mb-2 font-bold bg-gray-200 animated-background"></div>
                    <div className="w-32 h-4 mb-4 bg-gray-200 animated-background"></div>
                    <div className="flex items-center justify-between">
                      <div className="w-24 h-4 bg-gray-200 text-grayDark animated-background"></div>
                      <span className="px-4 py-1 text-xs text-white rounded-md bg-primary">
                        <div className="w-24 h-4 bg-gray-200 animated-background"></div>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DetailCategory
