import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../helpers/Root'

// export const announcementApi = createApi({
export const countnoficationchatApi = createApi({
  reducerPath: 'countnoficationchatApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['NotificationChat', 'AllNotificationChat'],
  endpoints: (builder) => ({
    getCountNotificationMessage: builder.query({
      query: (userId) => `api/notificationchats/${userId}`,
      providesTags: ['NotificationChat'],
    }),
  }),
})

export const { useGetCountNotificationMessageQuery } = countnoficationchatApi
