// export function formatDistance(distanceInMeters) {
//   const kilometers = Math.floor(distanceInMeters / 1000)
//   const meters = distanceInMeters % 1000

//   if (kilometers > 0) {
//     return kilometers + ' km'
//   } else {
//     return meters + ' m'
//   }
// }
import _ from 'lodash'

export function formatDistance(distance) {
  const kilometers = distance / 1000
  const meters = distance

  if (kilometers >= 1) {
    return _.round(kilometers) + ' km'
  } else {
    return _.round(meters) + ' m'
  }
}
