import React, { useState } from 'react'

const PricingPage = ({ setPricing, setStatus }) => {
  const [billingCycle, setBillingCycle] = useState('monthly')
  // const isDisabled = true // Remplacez par une logique dynamique si nécessaire
  setBillingCycle

  const pricing = ['free', 'pro', 'enterprise']

  return (
    <div className="min-h-screen px-4 py-10 pt-36 ">
      <div className="mx-auto text-center max-w-7xl">
        <h1 className="mb-3 text-3xl font-bold text-gray-900">
          {/* Créer votre boutique */}
          Tarification uty
        </h1>
        {/* <h1 className="mb-4 text-4xl font-bold text-gray-900">
          Forfaits et tarifs
        </h1> */}
        <p className="mb-6 text-gray-500 text-[15px]">
          Des plans adaptés à tous vos besoins <br />
          Tous les plans sont gratuits pendant la de periode de lancement!
        </p>
        {/* Toggle for billing cycle */}
        {/* <div className="flex items-center justify-center mb-12 space-x-4">
          <button
            onClick={() => setBillingCycle('monthly')}
            className={`py-2 px-6 rounded-md text-sm font-medium ${billingCycle === 'monthly'
              ? 'bg-gray-900 text-white'
              : 'bg-gray-200 text-gray-600'
              }`}
          >
            Monthly
          </button>
          <button
            onClick={() => setBillingCycle('annual')}
            className={`py-2 px-6 rounded-md text-sm font-medium ${billingCycle === 'annual'
              ? 'bg-gray-900 text-white'
              : 'bg-gray-200 text-gray-600'
              }`}
          >
            Annual{' '}
            <span className="font-semibold text-green-500">Save 25%</span>
          </button> 
        </div> */}

        {/* Pricing Cards */}
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          {/* Free Plan */}
          <div className="p-6 bg-white border border-gray-200 rounded-lg shadow-lg">
            <h2 className="mb-4 text-xl font-bold text-gray-900">Gratuit</h2>
            <p className="mb-6 text-gray-600">
              Parfait pour le vendeur informels
            </p>
            <div className="mb-6 text-4xl font-bold text-gray-900">$0</div>
            <h2 className="mb-4 text-xl font-bold text-gray-900">
              Gratuit /Toujours
            </h2>

            <ul className="mb-6 space-y-2 text-left text-gray-600 text-[15px]">
              <li>✅ Publication de 10 annonces par jour</li>
              <li>✅ Accès chat intégré</li>
              <li>✅ Notifications de produits et autres</li>
            </ul>
            <button
              className="w-full py-2 text-white bg-gray-900 rounded-md"
              onClick={() => {
                setPricing(pricing[0]), setStatus('checked')
              }}
            >
              {/* Commencez gratuitement */}
              Choisir ce plan
            </button>
          </div>

          {/* Pro Plan */}
          <div className="p-6 bg-white border border-blue-500 rounded-lg shadow-lg">
            <h2 className="mb-4 text-xl font-bold text-gray-900">
              Pro
              {/* <span className="text-blue-500">Populaire</span> */}
            </h2>
            <p className="mb-6 text-gray-600">Pour les commerces formels</p>
            <div className="mb-3 text-4xl font-bold text-gray-900 ">
              <span className="line-through">
                ${billingCycle === 'monthly' ? '5' : '3'}{' '}
              </span>
              <span className="text-sm font-normal">/mo</span>
            </div>
            <div className="text-4xl font-bold text-gray-900 ">
              <span className="mb-4 text-xl font-bold text-gray-900">
                Gratuit
              </span>
            </div>
            <div className="mb-2 text-4xl font-bold text-gray-900 ">
              <span className="text-sm font-normal">
                {' '}
                (Promotion de lancement)
              </span>
            </div>
            <ul className="mb-6 space-y-2 text-left text-gray-600 text-[15px]">
              <li>✅ {"Publication illimitée d'annonces"}</li>
              <li>✅ Priorité de visibilité des annonces</li>
            </ul>
            {/* <button
              disabled={isDisabled}
              className={`w-full py-2 rounded-md ${isDisabled
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'bg-blue-500 text-white'
                }`}
              onClick={() => {
                setPricing(pricing[1]), setStatus('checked')
              }}
            >
              Commencez avec Pro
            </button> */}

            <button
              className="w-full py-2 text-white bg-gray-900 rounded-md"
              onClick={() => {
                setPricing(pricing[0]), setStatus('checked')
              }}
            >
              Choisir ce plan
            </button>
          </div>

          {/* Enterprise Plan */}
          {/* <div className="p-6 text-white bg-gray-900 rounded-lg shadow-lg">
            <h2 className="mb-4 text-xl font-bold">Entreprise</h2>
            <p className="mb-6 text-gray-300">For multiple teams</p>
            <div className="mb-6 text-4xl font-bold">Custom</div>
            <ul className="mb-6 space-y-2 text-left text-gray-300">
              <li>✅ Everything in Pro</li>
              <li>✅ Up to 50 team members</li>
              <li>✅ 100 monitors</li>
              <li>✅ 25 status pages</li>
              <li>✅ 200+ integrations</li>
            </ul>
            <button
              disabled={isDisabled}
              className={`w-full py-2 rounded-md ${
                isDisabled
                  ? 'bg-gray-700 text-gray-500 cursor-not-allowed'
                  : 'bg-white text-gray-900'
              }`}
              onClick={() => {
                setPricing(pricing[2]), setStatus('checked')
              }}
            >
              Démarrez avec Entreprise
            </button>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default PricingPage
