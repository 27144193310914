import React from 'react'
import Nav from '../../components/layout/Nav'
import {
  useGetPendingShipmentsQuery,
  useGetDeliveriesQuery,
  useGetDeliveredShipmentsQuery,
} from '../../services/shipmentApi'
// import { useDispatch } from 'react-redux'
import LottieLoader from '../../components/LottieLoader'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'
import Shipments from '../../components/Shipments/Shipments'
import Deliveries from '../../components/Shipments/Deliveries'
// import { useSelector } from 'react-redux'
import DeliveredShipment from '../../components/Shipments/DeliveredShipments'

const tabs = (shipments, deliveries, data, currentLocation, isLoading) => [
  {
    name: 'En attente',
    component: (
      <Shipments
        shipments={shipments}
        currentLocation={currentLocation}
        isLoading={isLoading}
      />
    ),
    active: shipments !== undefined && deliveries?.length === 0 ? true : false,
  },
  {
    name: 'à livrées',
    component: <Deliveries deliveries={deliveries} />,
    active: shipments === undefined || deliveries?.length > 0 ? true : false,
  },
  {
    name: 'Effectuées',
    component: <DeliveredShipment deliveries={data} />,
    active: false,
  },
]

export default function PendingShipments() {
  //   const dispatch = useDispatch()
  let currentLocation = JSON.parse(localStorage.getItem('currentLocation'))
  const currentDeliver = JSON.parse(localStorage.getItem('currentDeliver'))
  const { data: shipments, isLoading } = useGetPendingShipmentsQuery()
  const { data: deliveries } = useGetDeliveriesQuery(currentDeliver?._id)
  const { data } = useGetDeliveredShipmentsQuery(currentDeliver?._id)
  //   const { data } = useGetSellerShipmentsQuery(params.id)
  console.log('shipments:', shipments, deliveries, currentDeliver)

  const [activeTab, setActiveTab] = React.useState(() => {
    return tabs(
      shipments,
      deliveries,
      data,
      currentLocation,
      isLoading
    ).findIndex((tab) => tab.active === true)
  })

  //   React.useEffect(() => {
  //     dispatch(setDropOffLocation(currentLocation))
  //   }, [])

  if (isLoading)
    return (
      <div>
        <Nav />
        <div className="flex flex-col pt-44 items-center justify-center">
          <div>
            <LottieLoader animation={indexLoader} />
          </div>
          <div>Chargement des nouvelles livraisons disponibles... </div>
        </div>
      </div>
    )

  return (
    <div className="bg-gray-100 min-h-screen">
      <Nav />
      <div className="pt-40 lg:px-48 px-5 h-auto">
        <div className="py-3 lg:text-[20px] font-roboto">
          Livraisons à effectuer
        </div>
        <div className="flex gap-2 sm:gap-4 mb-6">
          {tabs(shipments, deliveries, data, currentLocation, isLoading).map(
            (tab, index) => (
              <button
                key={index}
                className={`px-4 py-2 sm:px-6 sm:py-3 rounded-full font-medium text-sm sm:text-base text-gray-700 focus:outline-none ${
                  activeTab === index
                    ? 'bg-blue-500 text-white shadow-md'
                    : 'bg-white shadow hover:bg-gray-100'
                }`}
                onClick={() => setActiveTab(index)}
              >
                {tab.name}
              </button>
            )
          )}
        </div>
        <div className="tab-content">
          {
            tabs(shipments, deliveries, data, currentLocation, isLoading)[
              activeTab
            ].component
          }
        </div>
      </div>
    </div>
  )
}
