import React from 'react'
import { Modal, Textarea, Label, Spinner } from 'flowbite-react'
import OrderShipmentMap from './OrderShipmentMap'
import { useCreateShipmentMutation } from '../../services/shipmentApi'
import axios from 'axios'
import ShipmentDatas from '../Shipments/ShipmentDatas'
import { IoBagHandle } from 'react-icons/io5'
import { FaMapLocationDot } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

export function OrderShipment({ openModal, setOpenModal, order }) {
  const navigate = useNavigate()
  const [createShipment, { isLoading }] = useCreateShipmentMutation()

  const [description, setDescription] = React.useState('')
  const [openMap, setOpenMap] = React.useState(false)
  const [loadingDirection, setLoadingDirection] = React.useState(true) // Ajout de l'état pour le loader

  const [trajectory, setTrajectory] = React.useState({
    distance: '',
    duration: '',
  })

  const [loadingTrajectory, setLoadingTrajectory] = React.useState(false)
  const [direction, setDirection] = React.useState()

  const getDirection = async (order) => {
    setLoadingDirection(true)
    setLoadingTrajectory(true)

    let pickUpLocation = order?.pickUpLocation?.coordinates
    let dropOffAddress = order?.dropOffLocation?.coordinates

    console.log('coords', pickUpLocation, dropOffAddress)

    try {
      let result = await axios
        .get(
          `https://api.mapbox.com/directions/v5/mapbox/driving/${pickUpLocation[0]},${pickUpLocation[1]};${dropOffAddress[0]},${dropOffAddress[1]}?access_token=pk.eyJ1IjoidXR5LXdlYiIsImEiOiJjbHN0NTNrdXYxdDJiMmtuMXAxZHA2anM1In0.GRa3DMA8Q2f7x-iRij1ldw&geometries=geojson`
        )
        .then((response) => {
          setDirection(response)
          setLoadingTrajectory(false)
        })

      return result
    } catch (error) {
      console.error('Erreur lors de la récupération des directions :', error)
    } finally {
      setLoadingDirection(false) // Désactivation du loader
    }
  }

  const orderShip = async (order, comment) => {
    await createShipment({ order, comment }).then((response) => {
      console.log('res', response)
      navigate(`/shipments/${response?.data?._id}`)
    })
  }

  React.useEffect(() => {
    getDirection(order)
  }, [order])

  React.useEffect(() => {
    setTrajectory({
      distance: direction?.data?.routes[0]?.distance,
      duration: direction?.data?.routes[0]?.duration,
    })
  }, [direction])

  return (
    <>
      <Modal
        className="bg-white rounded-lg shadow-md"
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
      >
        <Modal.Header className="border-b border-gray-200 px-6 py-4">
          <div>
            <h4 className="font-semibold text-lg text-gray-800">
              Demander une livraison
            </h4>
            <p className="text-sm text-gray-500 flex items-center gap-1">
              <IoBagHandle />
              Commande {order?._id?.slice(6)}
            </p>
          </div>
        </Modal.Header>
        <Modal.Body className="px-6 py-4">
          {loadingDirection ? ( // Affichage du loader si loadingDirection est true
            <div className="flex justify-center items-center h-full">
              <Spinner
                color="blue"
                size="xl"
                aria-label="Chargement du trajet..."
              />
            </div>
          ) : (
            <>
              <ShipmentDatas
                trajectory={trajectory}
                loading={loadingTrajectory}
              />
              <div className="max-w-md mt-5">
                <div className="mb-2 block">
                  <Label
                    htmlFor="comment"
                    value="Ajouter un commentaire sur la livraison"
                    className="text-gray-700"
                  />
                </div>
                <Textarea
                  value={description}
                  onChange={(e) => {
                    e.preventDefault()
                    setDescription(e.target.value)
                  }}
                  id="comment"
                  placeholder="Ajouter un commentaire sur la livraison..."
                  required
                  rows={4}
                  className="rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div className="pt-5 pb-3">
                {openMap ? (
                  <div className="rounded-md shadow-md overflow-hidden">
                    <OrderShipmentMap
                      pickUpLocation={order?.pickUpLocation?.coordinates}
                      dropOffAddress={order?.dropOffLocation?.coordinates}
                      setTrajectory={setTrajectory}
                      direction={direction}
                    />
                  </div>
                ) : (
                  <button
                    className="px-4 py-2 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300 transition duration-300 flex items-center gap-1"
                    onClick={(e) => {
                      e.preventDefault()
                      setOpenMap(true)
                    }}
                  >
                    <FaMapLocationDot />
                    Voir le trajet sur la carte
                  </button>
                )}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="flex gap-2 px-6 py-4 border-t border-gray-200">
          <button
            className="px-4 py-2 rounded-md bg-blue-500 text-white font-medium hover:bg-blue-600 transition duration-300"
            onClick={(e) => {
              e.preventDefault()
              orderShip(order?._id, description)
            }}
          >
            {isLoading ? 'Commande en cours...' : 'Commander'}
          </button>
          <button
            className="px-4 py-2 rounded-md border border-gray-300 text-gray-700 hover:bg-gray-100 transition duration-300"
            onClick={() => setOpenModal(false)}
          >
            Annuler
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
