import React from 'react'
import { useNavigate } from 'react-router-dom'
import uty from '../assets/uty.png'

function Title() {
  const navigate = useNavigate()

  return (
    <div
      onClick={() => navigate('/')}
      className="flex items-center p-2 transition-transform transform cursor-pointer hover:scale-105"
      style={{ color: '#333' }}
    >
      <img src={uty} alt="" className="w-[40px]" />
      {/* <h2 className="page__title font-bold text-[28px] leading-5 font-payton flex space-x-1">
        <span className="text-blue-600">U</span>
        <span className="text-yellow-400">T</span>
        <span className="text-blue-500">Y</span>
      </h2> */}
    </div>
  )
}

export default Title
