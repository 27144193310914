import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../helpers/Root'

export const announcementApi = createApi({
  reducerPath: 'announcementApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['Announcements', 'AllAnnouncements'],
  endpoints: (builder) => ({
    createAnnouncement: builder.mutation({
      query: (announcement) => ({
        url: 'api/announcements/post',
        method: 'POST',
        body: announcement,
      }),
      invalidatesTags: ['Announcements', 'AllAnnouncements'],
    }),
    updateAnnouncement: builder.mutation({
      query: ({ announcement, id }) => ({
        url: `api/announcements/edit/${id}`,
        method: 'PATCH',
        body: announcement,
      }),
      invalidatesTags: ['Announcements'],
    }),
    markSoldAnnouncement: builder.mutation({
      query: (id) => ({
        url: `api/announcements/sold/${id}`,
        method: 'PATCH',
        body: null,
      }),
      invalidatesTags: ['Announcements'],
    }),
    getSellerAnnouncements: builder.query({
      query: (seller) => `api/announcements/seller/${seller}`,
      providesTags: ['Announcements'],
    }),
    getAllAnnouncements: builder.query({
      query: () => `api/announcements`,
      providesTags: ['AllAnnouncements'],
    }),
    getAnnouncement: builder.query({
      query: (announcementId) => `api/announcements/${announcementId}`,
    }),
    getAnnouncementsByCategory: builder.query({
      query: (category) => `api/announcements/category/${category}`,
    }),
    getFavoritesAnnouncements: builder.query({
      query: (userId) => `api/announcements/liked/${userId}`,
    }),
  }),
})

export const {
  useCreateAnnouncementMutation,
  useUpdateAnnouncementMutation,
  useMarkSoldAnnouncementMutation,
  useGetSellerAnnouncementsQuery,
  useGetAllAnnouncementsQuery,
  useGetAnnouncementsByCategoryQuery,
  useGetAnnouncementQuery,
  useGetFavoritesAnnouncementsQuery,
} = announcementApi
