import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../helpers/Root'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    updateUserLocation: builder.mutation({
      query: ({ user, location }) => ({
        url: `/api/users/new-location/${user}`,
        method: 'POST',
        body: { location: location },
      }),
    }),
    rate: builder.mutation({
      query: ({ user, rate }) => ({
        url: `/api/users/new-location/${user}`,
        method: 'POST',
        body: { rating: rate },
      }),
    }),
    checkNearSellers: builder.query({
      query: (user) => `api/users/check-near-sellers/${user}`,
    }),
    getCurrentUser: builder.query({
      query: (user) => `api/users/${user}`,
    }),
  }),
})

export const {
  useUpdateUserLocationMutation,
  useGetCurrentUserQuery,
  useCheckNearSellersQuery,
} = userApi
