import { Label, TextInput } from 'flowbite-react'
import React from 'react'

function LegalReference({
  setIdnat,
  setRccm,
  setNif,
  handleLegalRef,
  errors,
  isLoading,
  isidnat,
  isrccm,
  isnif,
}) {
  return (
    <div className="flex flex-col gap-8 p-6 pt-40 bg-white rounded-md shadow lg:mx-40 md:pt-40 lg:pt-40 md:bg-white ">
      <h1 className="text-3xl font-bold text-gray-900 ">
        {"Référence légale de l'entrepise"}
      </h1>

      {/* Name Section */}
      <div className="flex flex-col gap-3">
        <div className="flex gap-2">
          <Label
            className="font-semibold text-gray-700"
            htmlFor="name"
            value="Numéro d’identification Nationale (IDNAT)"
          />
          <span className="font-bold text-red-600">*</span>
        </div>
        <TextInput
          id="name"
          type="text"
          onChange={(e) => setIdnat(e.target.value)}
          placeholder="IDNAT"
          required
        />
        {errors.idnat && <p className="text-sm text-red-500">{errors.idnat}</p>}
        <p>
          {!isidnat && <p>{isidnat ? 'Code valide ✅' : 'Code invalide ❌'}</p>}
        </p>
      </div>

      {/* Phone Section */}
      <div className="flex flex-col gap-3">
        <div className="flex gap-2">
          <Label
            className="font-semibold text-gray-700"
            htmlFor="rccm"
            value="Registre du Commerce et du Crédit Mobilier (RCCM)"
          />
          <span className="font-bold text-red-600">*</span>
        </div>
        <TextInput
          id="rccm"
          type="text"
          onChange={(e) => setRccm(e.target.value)}
          placeholder="RCCM"
          required
        />
        {errors.rccm && <p className="text-sm text-red-500">{errors.rccm}</p>}
        <p>
          {!isrccm && <p>{isrccm ? 'Code valide ✅' : 'Code invalide ❌'}</p>}
        </p>
      </div>

      {/* Description Section */}
      <div className="flex flex-col gap-3">
        <div className="flex gap-2">
          <Label
            className="font-semibold text-gray-700"
            htmlFor="nif"
            value="Numéro d'identification fiscal (NIF)"
          />
          <span className="font-bold text-red-600">*</span>
        </div>
        <TextInput
          id="nif"
          type="text"
          onChange={(e) => setNif(e.target.value)}
          placeholder="NIF"
          required
        />
        {errors.nif && <p className="text-sm text-red-500">{errors.nif}</p>}

        <p>
          {!isnif && <p>{isnif ? 'Code valide ✅' : 'Code invalide ❌'}</p>}
        </p>
      </div>

      <button
        className="py-3 text-white bg-green-500 rounded-md hover:bg-green-600"
        onClick={handleLegalRef}
        disabled={isLoading}
      >
        {isLoading ? 'Création en cours...' : 'Créer enseigne'}
      </button>
    </div>
  )
}

export default LegalReference
