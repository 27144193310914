import React from 'react'
import { useParams } from 'react-router-dom'
import LottieLoader from '../../components/LottieLoader'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'
import OrderShipmentMap from '../../components/orders/OrderShipmentMap'
import StartShipment from '../../components/Shipments/StartShipment'
import EndShipment from '../../components/Shipments/EndShipment'
import { useGetShipmentQuery } from '../../services/shipmentApi'
import Nav from '../../components/layout/Nav'
import ShipmentDatas from '../../components/Shipments/ShipmentDatas'
import { IoChevronDown, IoChevronUpSharp } from 'react-icons/io5'
import axios from 'axios'
import RatingShipment from '../../components/Shipments/Rating'
import { useSelector } from 'react-redux'
import AlternateTimeline from '../../components/Shipments/AlternateTimeLine'
import ShipmentHeader from '../../components/Shipments/ShipmentHeader'

export default function Shipment() {
  const params = useParams()
  const currentUser = useSelector((state) => state.user.currentUser)
  const { data: shipment, isLoading } = useGetShipmentQuery(params.id, {
    pollingInterval: 30000,
    refetchOnMountOrArgChange: true,
    skip: false,
  })

  const [direction, setDirection] = React.useState({})
  const [trajectory, setTrajectory] = React.useState({
    distance: '',
    duration: '',
  })
  const [loadingTrajectory, setLoadingTrajectory] = React.useState(false)
  const [showMap, setShowMap] = React.useState(false)
  const [rating, setRating] = React.useState(false)

  const getDirection = async (shipment) => {
    let pickUpLocation = shipment?.order?.pickUpLocation?.coordinates
    let dropOffAddress = shipment?.order?.dropOffLocation?.coordinates
    let deliverLocation = shipment?.deliver?.user?.location?.coordinates
    setLoadingTrajectory(true)

    await axios
      .get(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${pickUpLocation[0]},${pickUpLocation[1]};${dropOffAddress[0]},${dropOffAddress[1]}?access_token=pk.eyJ1IjoidXR5LXdlYiIsImEiOiJjbHN0NTNrdXYxdDJiMmtuMXAxZHA2anM1In0.GRa3DMA8Q2f7x-iRij1ldw&geometries=geojson`
      )
      .then((response) => {
        console.log('direction response:', response?.data)
        setDirection(response)
        setLoadingTrajectory(false)
      })

    let result =
      shipment?.status !== 'in delivery'
        ? await axios
            .get(
              `https://api.mapbox.com/directions/v5/mapbox/driving/${pickUpLocation[0]},${pickUpLocation[1]};${dropOffAddress[0]},${dropOffAddress[1]}?access_token=pk.eyJ1IjoidXR5LXdlYiIsImEiOiJjbHN0NTNrdXYxdDJiMmtuMXAxZHA2anM1In0.GRa3DMA8Q2f7x-iRij1ldw&geometries=geojson`
            )
            .then((response) => setDirection(response))
        : await axios.get(
            `https://api.mapbox.com/directions/v5/mapbox/driving/${dropOffAddress[0]},${dropOffAddress[1]};${deliverLocation[0]},${deliverLocation[1]}?access_token=pk.eyJ1IjoidXR5LXdlYiIsImEiOiJjbHN0NTNrdXYxdDJiMmtuMXAxZHA2anM1In0.GRa3DMA8Q2f7x-iRij1ldw&geometries=geojson`
          )

    return result
  }

  console.log('ship order', shipment)

  React.useEffect(() => {
    getDirection(shipment)
    if (
      shipment?.status === 'delivered' &&
      !shipment?.isRated &&
      currentUser?._id === shipment?.order?.customer?._id
    )
      setRating(true)
  }, [shipment])

  React.useEffect(() => {
    setTrajectory({
      distance: direction?.data?.routes[0]?.distance,
      duration: direction?.data?.routes[0]?.duration,
    })
  }, [direction])

  if (isLoading)
    return (
      <div>
        <Nav />
        <div className="flex flex-col pt-44 items-center justify-center">
          <div>
            <LottieLoader animation={indexLoader} />
          </div>
          <div>Chargement des nouvelles livraisons disponibles... </div>
        </div>
      </div>
    )

  return (
    <div className="bg-gray-100 min-h-screen">
      {' '}
      {/* Light background */}
      <Nav />
      <div className="mx-0 md:mx-36 py-16 pt-36 px-2 md:px-10">
        {isLoading ? (
          <div className="flex flex-col items-center justify-center h-64">
            <LottieLoader animation={indexLoader} />
            <p>Chargement des détails de la livraison...</p>
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow-md p-5 md:p-10">
            {' '}
            {/* Card container */}
            <div className="mb-6">
              {' '}
              {/* Header section */}
              <h3 className="text-xl font-semibold text-gray-800 mb-5">
                Livraison de la commande {shipment?.order?._id?.slice(-6)}
              </h3>
              <div className="flex flex-col items-center gap-4 mt-2">
                <ShipmentHeader shipment={shipment} />
                {/* Action buttons */}
                <div>
                  {shipment?.deliver && shipment?.status == 'accepted' && (
                    <StartShipment shipment={shipment} />
                  )}
                  {shipment?.status === 'in delivery' && (
                    <EndShipment shipment={shipment} />
                  )}
                </div>
              </div>
            </div>
            <div className="mb-6">
              {' '}
              {/* Shipment details section */}
              <ShipmentDatas
                trajectory={trajectory}
                loading={loadingTrajectory}
              />
            </div>
            <div className="flex flex-col lg:flex-row lg:gap-6">
              <div className="w-full lg:w-1/3 mb-4 lg:mb-0">
                {' '}
                {/* Map section */}
                <div
                  className="flex items-center justify-between px-4 py-3 bg-gray-100 rounded-md cursor-pointer"
                  onClick={() => setShowMap(!showMap)}
                >
                  <span className="text-gray-700 font-medium">
                    Voir le trajet sur la carte
                  </span>
                  {showMap ? <IoChevronUpSharp /> : <IoChevronDown />}
                </div>
                {showMap && (
                  <div className="mt-4 rounded-md shadow-md overflow-hidden">
                    <OrderShipmentMap
                      pickUpLocation={
                        shipment?.order?.pickUpLocation?.coordinates
                      }
                      dropOffAddress={
                        shipment?.order?.dropOffLocation?.coordinates
                      }
                      deliverLocation={
                        shipment?.deliver?.user?.location?.coordinates
                      }
                      setTrajectory={setTrajectory}
                      direction={direction}
                      shipmentStatus={shipment?.status}
                      shipment={shipment}
                    />
                  </div>
                )}
              </div>

              <div className="w-full lg:w-2/3">
                {' '}
                {/* Timeline section */}
                <AlternateTimeline shipment={shipment} />
              </div>
            </div>
          </div>
        )}

        <RatingShipment
          openModal={rating}
          setOpenModal={setRating}
          shipment={shipment}
        />
      </div>
    </div>
  )
}
