import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../helpers/Root'

export const deliverApi = createApi({
  reducerPath: 'deliverApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['Deliver'],
  endpoints: (builder) => ({
    getDeliver: builder.query({
      query: (deliver) => `api/delivers/get-deliver/${deliver}`,
      providesTags: ['Deliver'],
    }),
    getDeliverByUser: builder.query({
      query: (user) => `api/delivers/${user}`,
    }),
    acceptShipment: builder.mutation({
      query: ({ deliver, shipment }) => ({
        url: `/api/shipments/accept/${shipment}`,
        method: 'POST',
        body: { deliver: deliver },
      }),
    }),
    rate: builder.mutation({
      query: ({ rate, deliver, shipment }) => ({
        url: `/api/delivers/rate/${deliver}`,
        method: 'POST',
        body: { rating: rate, shipment: shipment },
      }),
      invalidatesTags: ['Deliver'],
    }),
  }),
})

export const {
  useGetDeliverQuery,
  useGetDeliverByUserQuery,
  useAcceptShipmentMutation,
  useRateMutation,
} = deliverApi
