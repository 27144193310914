// import { FaCartArrowDown } from 'react-icons/fa'
import { FaRegMessage } from 'react-icons/fa6'
import { FiShoppingCart } from 'react-icons/fi'
// import { IoChatboxEllipses } from 'react-icons/io5'

let currentUser = JSON.parse(localStorage.getItem('currentUser'))

const links_options = (cart) => {
  return [
    {
      title: 'Panier',
      icon: (
        <div className="relative cursor-pointer bg-green">
          {cart?.length > 0 && (
            <div
              className={` bg-red-500 absolute top-[-6px] left-[16px] text-[9px] py-[1px] text-white ${cart?.length > 9 ? 'px-[3px]' : 'px-[5px]'}  rounded-full`}
            >
              {cart?.length}
            </div>
          )}
          <FiShoppingCart className="w-6 h-6 text-blue-500 hover:text-secondary-300" />
        </div>
      ),
      url: '/',
    },
  ]
}

const links = (messages, counterMessage) => [
  {
    title: 'Messages',
    icon: (
      <div className="relative cursor-pointer bg-green">
        {counterMessage > 0 && (
          <div
            className={` bg-red-500 absolute top-[-6px] left-[17px] text-[9px] py-[1px] text-white ${messages?.length > 9 ? 'px-[3px]' : 'px-[5px]'}  rounded-full`}
          >
            {counterMessage}
          </div>
        )}
        <FaRegMessage className="w-6 h-6 text-blue-500 hover:text-secondary-300" />
      </div>
    ),
    url: `/conversations/${currentUser?._id}`,
  },
]

export { links_options, links }
