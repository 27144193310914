import { createSlice } from '@reduxjs/toolkit'

// Définir l'état initial du panier, un tableau vide
const initialState = {
  messages: [],
}

// Créer le slice du panier
const messageSlice = createSlice({
  name: 'message', // Le nom du slice
  initialState, // L'état initial
  reducers: {
    // Les réducteurs, qui modifient l'état en fonction des actions
    // Chaque réducteur génère automatiquement une action correspondante
    setMessages: (state, action) => {
      state.messages = action.payload
    },
  },
})

// Exporter les actions générées par le slice
export const { setMessages } = messageSlice.actions

// Exporter le réducteur du slice
export default messageSlice.reducer
