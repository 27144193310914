import React from 'react'
import {
  IoBagOutline,
  IoEyeOutline,
  // IoCloseOutline,
  // IoCheckmark,
} from 'react-icons/io5'
import { NumericFormat } from 'react-number-format'
import StatusBadge from '../Badge'
// import { FaTruckFast } from 'react-icons/fa6';
import { DetailModal } from '../../components/announcement/DetailModal'
import { OrderShipment } from './OrderShipment'
import Shipment from '../Shipments/Shipment'
import { useGetOrderShipmentQuery } from '../../services/shipmentApi'
import CallUser from '../Shipments/CallUser'
import { useNavigate } from 'react-router-dom'

function OrderItem({ order, acceptOrder, refuseOrder, accepting }) {
  const navigate = useNavigate()
  const [show, setShow] = React.useState(false)
  const [orderingShip, setOrderingShip] = React.useState(false)
  const [selected, setSelected] = React.useState()
  const [openShip, setOpenShip] = React.useState(false)

  const { data: shipment } = useGetOrderShipmentQuery(order?._id)
  console.log('shipment', shipment, order)

  let currentLocation = JSON.parse(localStorage.getItem('currentLocation'))

  return (
    <div className="bg-white rounded-lg shadow-md p-4 mb-4">
      {/* Card container */}
      <div className="flex flex-row items-center justify-between mb-4">
        {/* Header */}
        <div className="flex items-center gap-2">
          <IoBagOutline className="text-gray-500" />
          <span className="font-medium text-gray-800 truncate w-32">
            Commande {order?._id?.slice(-5)}
          </span>
        </div>
        <StatusBadge status={order?.status} />
      </div>

      {/* Product details */}
      <div className="divide-y divide-gray-200">
        {order?.products?.map((product, index) => (
          <div key={index} className="py-3">
            <div className="flex items-center">
              <img
                src={product?.productId?.images[0]}
                alt={product?.productId?.name}
                className="h-16 w-16 rounded-md object-cover mr-4"
              />
              <div className="flex-grow">
                <div className="font-medium text-gray-800 w-32 lg:w-56 truncate">
                  {product?.productId?.name}
                </div>
                <div className="text-gray-600 text-sm">
                  {product?.quantity} x{' '}
                  <NumericFormat
                    value={product?.productId?.price}
                    displayType={'text'}
                    thousandSeparator=" "
                    suffix={` ${
                      product?.productId?.currency?.shortName || 'fc'
                    }`}
                  />
                </div>
              </div>
              {/* View Product button (You can style this further) */}
              <button
                onClick={() => {
                  setShow(true)
                  setSelected(product?.productId?._id)
                }}
                className="px-3 py-2 rounded-md text-sm font-medium text-blue-700 bg-blue-100 hover:bg-blue-200 transition duration-300"
              >
                <IoEyeOutline className="inline-block mr-2" />
                Voir
              </button>
            </div>
            {/* Call User and View Product button (You can style these further) */}
            {order?.status === 'in pickup' && (
              <div className="mt-2">
                <CallUser
                  user={shipment?.deliver?.user}
                  title={'le livreur'}
                  isDeliver={true}
                />
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Actions */}
      <div className="mt-4 flex flex-col sm:flex-row gap-2 sm:justify-end">
        {order?.status === 'pending' && (
          <>
            <button
              onClick={() => acceptOrder(order?._id, currentLocation)}
              className="px-4 py-2 rounded-md bg-green-500 text-white font-medium hover:bg-green-600 transition duration-300"
            >
              {accepting ? 'Accepter...' : 'Accepter la commande'}
            </button>
            <button
              onClick={() => refuseOrder(order?._id)}
              className="px-4 py-2 rounded-md bg-red-500 text-white font-medium hover:bg-red-600 transition duration-300"
            >
              Refuser
            </button>
          </>
        )}

        {/* Other action buttons based on order status (You can style these further) */}
        {order?.status === 'accepted' && (
          <button
            onClick={() => setOrderingShip(true)}
            className="px-4 py-2 rounded-md bg-blue-500 text-white font-medium hover:bg-blue-600 transition duration-300"
          >
            Commander la livraison
          </button>
        )}
        {(order?.status === 'awaiting delivery' ||
          order?.status === 'in pickup') && (
          <button
            onClick={() => {
              navigate(`/shipments/${shipment?._id}`)
            }}
            className="px-4 py-2 rounded-md bg-blue-500 text-white font-medium hover:bg-gray-600 transition duration-300"
          >
            Voir la commande de livraison
          </button>
        )}
        {(order?.status === 'in delivery' || order?.status === 'delivered') && (
          <button
            onClick={() => navigate(`/shipments/${shipment?._id}`)}
            className="px-4 py-2 rounded-md bg-blue-500 text-white font-medium hover:bg-blue-600 transition duration-300"
          >
            Suivre la livraison
          </button>
        )}
      </div>

      {/* Modals */}
      {show && (
        <DetailModal
          openModal={show}
          setOpenModal={setShow}
          announcementId={selected}
        />
      )}
      {orderingShip && (
        <OrderShipment
          openModal={orderingShip}
          setOpenModal={setOrderingShip}
          order={order}
        />
      )}
      {openShip && (
        <Shipment
          openModal={openShip}
          setOpenModal={setOpenShip}
          shipment={shipment}
        />
      )}
    </div>
  )
}

export default OrderItem
