import React from 'react'
import MainForm from '../../components/shops/MainForm'
import Nav from '../../components/layout/Nav'
import PricingPage from './PricingPage'

export default function CreateShop() {
  const [status, setStatus] = React.useState('checking')
  const [pricing, setPricing] = React.useState(null)

  return (
    <div>
      <Nav />
      <div className="flex flex-col sm:px-0 md:px-6 lg:px-72">
        {/* <div className="flex flex-col pt-40"> */}
        {/* <h6 className="text-[25px] mb-3">Créer votre boutique</h6> */}
        {status === 'checking' && (
          <PricingPage setPricing={setPricing} setStatus={setStatus} />
        )}
        {status === 'checked' && <MainForm pricing={pricing} />}
      </div>
    </div>
  )
}
