import React from 'react'
import { IoTrashSharp, IoEye } from 'react-icons/io5'
import { BsCartX } from 'react-icons/bs'
import { formatCreatedToday } from '../../helpers/FormatCreatedToday'
import { useNavigate } from 'react-router-dom'
import { NumericFormat } from 'react-number-format'
import LottieLoader from '../../components/LottieLoader'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'

export default function AllProductInCart({
  cart,
  user,
  handleDeleteProductToCart,
  handleUpdateProductQuantity,
  isLoading,
  deleting,
}) {
  const navigate = useNavigate()

  if (isLoading)
    return (
      <div className="flex flex-col items-center justify-center pt-10 w-full">
        <LottieLoader animation={indexLoader} />
        <div className="mt-4 text-gray-600">
          Chargement des produits ajoutés au panier...
        </div>
      </div>
    )

  if (!cart || cart?.products?.length === 0) {
    return (
      <div className="flex flex-col gap-5 justify-center items-center pt-20 w-full">
        <BsCartX className="text-6xl text-gray-400" />
        <div className="text-lg text-gray-600">
          Vous n{"'"}avez pas de produit dans votre panier
        </div>
        <button
          className="flex items-center gap-2 bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-md"
          onClick={() => {
            navigate(`/orders/me/${user}`)
          }}
        >
          Voir mes commandes
        </button>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-6 mb-6">
      {cart?.products?.map((product, index) => (
        <div
          key={index}
          className="bg-white rounded-lg shadow-md p-4 flex flex-col md:flex-row items-center"
        >
          {/* Image */}
          <div className="w-full md:w-1/4 mb-4 md:mb-0 md:mr-4">
            <img
              className="h-32 w-full object-cover rounded-md"
              src={product?.productId?.images[0]}
              alt={product?.productId?.name}
            />
          </div>

          {/* Nom du produit et détails */}
          <div className="flex-grow w-full md:w-1/2">
            <h3 className="text-xl font-semibold text-gray-800 truncate">
              {product?.productId?.name}
            </h3>
            <p className="text-gray-600 mt-1">
              Prix unitaire :{' '}
              <NumericFormat
                value={product?.productId?.price}
                displayType={'text'}
                thousandSeparator=" "
                suffix={` ${product.productId.currency ? product?.productId?.currency?.shortName : 'FC'}`}
              />
            </p>

            {/* Quantité */}
            <div className="mt-4 flex items-center gap-2">
              <label
                htmlFor={`quantity-${index}`}
                className="text-sm font-medium text-gray-700"
              >
                Quantité
              </label>
              <select
                id={`quantity-${index}`}
                className="mt-1 block w-20 py-1 px-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-sm"
                value={product?.quantity}
                onChange={(e) =>
                  handleUpdateProductQuantity(
                    user,
                    product?.productId?._id,
                    parseInt(e.target.value, 10)
                  )
                }
              >
                {[...Array(51).keys()].slice(1).map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Prix total et actions */}
          <div className="flex flex-col items-end w-full md:w-1/4 mt-4 md:mt-0">
            <p className="text-lg font-semibold text-gray-800">
              Total :{' '}
              <NumericFormat
                value={product?.productId?.price * product?.quantity}
                displayType={'text'}
                thousandSeparator=" "
                suffix={` ${product?.productId?.currency ? product?.productId?.currency?.shortName : 'CDF'}`}
              />
            </p>
            <p className="text-sm text-gray-500 mt-1">
              {formatCreatedToday(cart?.createdAt)}
            </p>

            {/* Boutons d'action */}
            <div className="mt-4 flex items-center gap-2">
              <button
                onClick={() =>
                  navigate(
                    `/announcements/${product?.productId?._id}/${product?.productId?.category}`
                  )
                }
                className="flex items-center gap-1 bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-3 rounded-md text-sm transition duration-150"
              >
                <IoEye className="text-lg" />
                <span>Voir</span>
              </button>
              <button
                className="flex items-center gap-1 bg-red-600 hover:bg-red-700 text-white font-medium py-2 px-3 rounded-md text-sm transition duration-150"
                onClick={(e) =>
                  handleDeleteProductToCart(e, user, product?.productId?._id)
                }
              >
                <IoTrashSharp className="text-lg" />
                <span>
                  {deleting ? 'Suppression en cours...' : 'Supprimer'}
                </span>
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
