import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
}

// export const onlineUsersSlice = createSlice({
export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setCounterMesssage: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { setCounterMesssage } = counterSlice.actions

export default counterSlice.reducer
