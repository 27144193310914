import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const getNotificationsConversation = async (userId, setNotificationChat) => {
  await axios
    .get(`${BASE_URL}/api/notificationchats/conversation/${userId}`)
    .then((response) => {
      setNotificationChat(response?.data?.data)
    })
}

export { getNotificationsConversation }
