import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const cleanNotificationsConversation = async (
  userId,
  chatId,
  setCleanNotifConversation
) => {
  await axios
    .patch(`${BASE_URL}/api/notificationchats/conversation/${userId}/${chatId}`)
    .then((response) => {
      if (response.data.message === 'success') {
        setCleanNotifConversation(response.data)
      }
    })
}

export { cleanNotificationsConversation }
