import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IoBagOutline, IoEyeOutline } from 'react-icons/io5'
import { NumericFormat } from 'react-number-format'
import StatusBadge from '../Badge'
import { FaTruckFast } from 'react-icons/fa6'
import { useGetOrderShipmentQuery } from '../../services/shipmentApi'

export default function OrderCustomerItem({ order }) {
  const navigate = useNavigate()

  const { data: shipment } = useGetOrderShipmentQuery(order?._id)

  const formattedPrice = (price, currency) => (
    <NumericFormat
      value={price}
      displayType={'text'}
      thousandSeparator=" "
      suffix={` ${currency?.shortName || 'fc'}`}
    />
  )

  return (
    <div className="bg-white rounded-lg shadow-md p-4 mb-4">
      {/* Header */}
      <div className="flex items-center justify-between mb-2">
        <h3 className="font-medium text-gray-900">
          <IoBagOutline className="inline-block mr-2 text-gray-500" />
          Commande {order?._id?.slice(-5)}
        </h3>
        <StatusBadge status={order?.status} />
      </div>

      {/* Products */}
      <ul className="divide-y divide-gray-200">
        {order?.products?.map((product, index) => (
          <li key={index} className="py-3">
            <div className="flex items-center">
              <img
                src={product?.productId?.images[0]}
                alt={product?.productId?.name}
                className="h-16 w-16 rounded-md object-cover mr-4"
              />
              <div className="flex-grow">
                <div className="text-lg font-medium text-gray-900 truncate w-24 lg:w-56">
                  {product?.productId?.name}
                </div>
                <div className="text-gray-600">
                  {product?.quantity} x{' '}
                  {formattedPrice(
                    product?.productId?.price,
                    product?.productId?.currency
                  )}
                </div>
              </div>
              <button
                className="flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-blue-700 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300"
                onClick={() =>
                  navigate(
                    `/announcements/${product?.productId?._id}/${product?.productId?.category}`
                  )
                }
              >
                <IoEyeOutline className="inline-block mr-2" />
                <span className="hidden md:inline">Voir</span>
              </button>
            </div>
          </li>
        ))}
      </ul>

      {/* Actions */}
      <div className="mt-4 flex justify-end">
        {shipment && (
          <button
            className="flex items-center gap-1 px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition duration-300"
            onClick={() => navigate(`/shipments/${shipment?._id}`)}
          >
            {shipment.status !== 'delivered' && (
              <>
                <FaTruckFast className="mr-2" />
                Suivre la livraison
              </>
            )}
            {shipment.status === 'delivered' && !shipment.isRated && (
              <>Noter le livreur</>
            )}
            {shipment.isRated && <>Revoir la livraison</>}
          </button>
        )}
      </div>
    </div>
  )
}
