import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../helpers/Root'

export const notificationApi = createApi({
  reducerPath: 'notificationApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['Notifications'],
  endpoints: (builder) => ({
    addNotification: builder.mutation({
      query: (data) => ({
        url: 'api/notifications/add',
        method: 'POST',
        body: data,
      }),

      invalidatesTags: ['Notifications'],
    }),

    markReaded: builder.mutation({
      query: (id) => ({
        url: `api/notifications/readed/${id}`,
        method: 'PATCH',
        body: { id: id },
      }),

      invalidatesTags: ['Notifications'],
    }),

    getNotifications: builder.query({
      query: (id) => `api/notifications/${id}`,
      providesTags: ['Notifications'],
    }),
  }),
})

export const {
  useAddNotificationMutation,
  useMarkReadedMutation,
  useGetNotificationsQuery,
} = notificationApi
