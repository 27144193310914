import React from 'react'
import { useParams } from 'react-router-dom'

import { FaMoneyBillTrendUp } from 'react-icons/fa6'
import ItemProduct from '../../components/ItemProduct'

import PageLoader from '../../components/PageLoader'
import Nav from '../../components/layout/Nav'
import ShopInfo from '../../components/shops/ShopInfo'
import { useGetShopQuery } from '../../services/shopApi'

export default function ShopProducts() {
  let params = useParams()
  let { data: shop, isLoading } = useGetShopQuery(params.id)
  let announcements = shop?.announcements

  if (isLoading)
    return (
      <div>
        <Nav />
        <PageLoader message="Chargement de la boutique..." />
      </div>
    )

  if (announcements?.length === 0) {
    return (
      <div className="">
        <Nav />
        <div className="pt-40">
          <div className="px-5 md:px-20">
            <ShopInfo shop={shop} />
          </div>
          <div className="flex flex-col gap-5 justify-center items-center mt-5">
            <FaMoneyBillTrendUp className="text-[50px] text-slate-500" />
            <div>{"Cette boutique n'a pas d'annonces pour le moment"}</div>
          </div>
        </div>
      </div>
    )
  }
  //if user is seller get all annoucements published else get annoucement published when it was user

  return (
    <div className=" ">
      <Nav />
      <div className="px-[16px] lg:px-16 w-[100%] pt-[150px]">
        <ShopInfo shop={shop} />
        <div></div>
        <div className="flex justify-between items-center pb-5 md:justify-between pt-10 md:items-center w-[100%]">
          <div className="">
            <h3 className="text-[15px] md:text-[25px] lg:text-[15px]">
              Les annonces de l{"'"}enseigne
            </h3>
          </div>
        </div>
        {announcements?.length > 0 && (
          <div className="grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-5">
            <ItemProduct data={announcements} />
          </div>
        )}
      </div>
    </div>
  )
}
