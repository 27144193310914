import React from 'react'
import Banner from '../../assets/banner/banner-1/banner-3.jpg'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useGetAllAnnouncementsQuery } from '../../services/announcementApi'
import { useNavigate } from 'react-router-dom'

AOS.init()

function BannerUty() {
  const { isLoading } = useGetAllAnnouncementsQuery()
  const navigate = useNavigate()

  return !isLoading ? (
    <div
      className="relative"
      // data-aos="fade-up" data-aos-duration="400"
    >
      <div
        className=" object-cover lg:mx-16 h-[240px] lg:h-[270px] lg:block"
        style={{
          backgroundImage: `url(${Banner})`,
          borderRadius: '30px',
          marginBottom: '50px',
          objectFit: 'cover',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className="lg:mx-16 rounded-[30px] absolute  bg-[rgba(31,30,30,0.68)] inset-0">
          <div className="absolute inset-0 flex flex-col justify-center">
            <div className="flex flex-col items-center p-2 lg:flex-row lg:p-6">
              <div className="text-white ">
                <h1 className="hidden w-full mb-4 text-2xl font-bold lg:block lg:text-4xl">
                  Plateforme e-commerce de confiance.
                </h1>
                <h1 className="block w-full mb-4 text-4xl font-bold text-center lg:hidden lg:text-4xl">
                  uty ma confiance
                </h1>
                <p className="hidden lg:block mb-8 lg:w-[700px] text-lg">
                  {
                    " Découvrez une vaste gamme de produits sélectionnés pour vous, avec des offres exclusives et une expérience d'achat unique."
                  }
                </p>
                <p className="block text-center lg:hidden mb-8 lg:w-[700px] text-xl">
                  {
                    " Découvrez de produits sélectionnés pour vous, avec des offres exclusives et une expérience d'achat unique."
                  }
                </p>
              </div>
              <div className="flex items-center lg:absolute lg:inset-y-1/4 lg:right-4">
                <button
                  className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                  onClick={() => navigate('/categories')}
                >
                  Voir les annonces
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  )
}

export default BannerUty

function Loader() {
  return (
    <div className="w-full pb-8 md:px-12 lg:px-16">
      <div className="flex flex-col items-center gap-2 px-2">
        <div
          alt=""
          className="flex-shrink-0 mt-[6px] w-full lg:mt-0 h-[90px] rounded-[5px]  md:h-[115px] lg:h-[155px] hover:cursor-pointer  image animated-background"
        />
      </div>
    </div>
  )
}

export { Loader, BannerUty }
