import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IoSearchCircleSharp } from 'react-icons/io5'

function SearchIcon() {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/searching')
  }
  return (
    <div
      className="text-slate-500 md:hidden block text-[30px]"
      onClick={handleClick}
    >
      <IoSearchCircleSharp />
    </div>
  )
}

export default SearchIcon
