import { useEffect, useState } from 'react'
import { FaRegCommentDots, FaRegHeart } from 'react-icons/fa'
import { FaEye } from 'react-icons/fa6'
import { GrStatusGood } from 'react-icons/gr'
import { FaHeart } from 'react-icons/fa'

export default function ImageAnnounceCarrousel({
  images,
  announcement,
  handleLikeAnnouncement,
  user,
  generateCodeForNoUser,
  setIsShowComment,
}) {
  const [selectedImage, setSelectedImage] = useState(null)

  const changeImage = (image) => {
    setSelectedImage(image)
  }

  useEffect(() => {
    setSelectedImage(images ? images[0] : null)
  }, [images])

  return (
    <div className="flex flex-col gap-4 pt-4 lg:flex md:pt-10 lg:pt-0 lg:flex-row">
      <div className="relative bg-gray-300 w-full h-[280px] pt-0 md:pt-6 lg:pt-0 md:w-full md:h-[350px] lg:w-[420px] lg:h-[340px] lg:order-2">
        <img
          src={selectedImage || (images && images[0])}
          alt=""
          className="h-[280px] w-full md:w-full md:h-[360px] lg:w-[420px] lg:h-[340px] object-cover lg:origin-bottom"
        />

        {/* Icons (Right Aligned) */}
        <div className="absolute flex flex-col gap-2 top-2 right-2">
          {/* Comment Icon */}
          <div
            className="flex items-center justify-center p-2 text-white bg-blue-500 rounded-full cursor-pointer lg:hidden"
            onClick={() => setIsShowComment(true)}
          >
            <FaRegCommentDots className="text-[27px]" />
          </div>

          {/* Like Icon */}
          <div
            className="flex items-center justify-center p-2 text-white bg-blue-500 rounded-full cursor-pointer"
            onClick={() => handleLikeAnnouncement(announcement)}
          >
            {announcement?.likes?.includes(user?._id) ||
            announcement?.likes?.includes(generateCodeForNoUser) ? (
              // <FaThumbsUp className="text-red-400 text-[27px]" />
              <FaHeart className="text-red-400 text-[27px] " />
            ) : (
              // <FaThumbsUp className="text-white text-[27px]" />
              <FaRegHeart className="text-white text-[27px]" />
            )}
          </div>

          {/* View Icon */}
          <div className="flex items-center justify-center p-2 text-white bg-blue-500 rounded-full cursor-pointer lg:hidden">
            <FaEye className="text-[27px]" />
          </div>
        </div>
      </div>

      <div
        className={
          images?.length > 6
            ? 'grid gap-2 grid-flow-col overflow-x-scroll pt-0 md:pt-12 lg:pt-0 lg:h-[380px] lg:grid-flow-row lg:gap-2 lg:order-1'
            : 'flex gap-2 overflow-x-hidden pt-0 md:pt-12 lg:pt-0 lg:flex-col lg:gap-2 lg:h-[380px] lg:order-1'
        }
      >
        {images?.map((image, index) => (
          <div
            key={index}
            className="relative rounded-[3px] h-[50px] w-[50px] md:h-[60px] md:w-[60px] lg:h-[50px] lg:w-[50px] border cursor-pointer"
            onMouseEnter={() => changeImage(image)}
          >
            <div className="absolute z-10 h-[50px] w-[50px] md:w-[60px] md:h-[60px] lg:h-[50px] lg:w-[50px] cursor-pointer">
              <img
                src={image}
                alt=""
                className="h-[50px] w-[50px] md:w-[60px] md:h-[60px] lg:h-[50px] lg:w-[50px] border rounded object-cover object-center md:origin-bottom"
              />
            </div>
            {selectedImage === image && (
              <div className="absolute justify-center p-[3px] z-20 h-[50px] w-[50px] md:h-[60px] md:w-[60px] lg:h-[50px] lg:w-[50px] border cursor-pointer bg-[rgba(0,0,0,0.53)]">
                <GrStatusGood className="text-xl text-white" />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
