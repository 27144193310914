import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from '../../helpers/Root'
import axios from 'axios'

export const getCurrentCart = createAsyncThunk(
  'cart/getCurrentCart',
  async (user) => {
    console.log(user)
    let response = await axios.get(`${BASE_URL}/api/cart/${user}`) // Remplacez ceci par l'URL de votre API
    return response.data
  }
)

export const updateQuantity = createAsyncThunk(
  'cart/updateQuantity',
  async ({ cart, product, quantity }) => {
    console.log(cart)
    let response = await axios.post(`${BASE_URL}/api/cart/quantity`, {
      cartId: cart,
      productId: product,
      quantity: quantity,
    })

    console.log('cart updated:', response.data)
    return response.data
  }
)

export const deleteToCart = createAsyncThunk(
  'cart/deleteToCart',
  async ({ user, product }) => {
    console.log(user)
    let response = await axios.post(
      `${BASE_URL}/api/cart/delete-product/${user}`,
      {
        productId: product,
      }
    )
    return response.data
  }
)

export const createOrder = createAsyncThunk(
  'cart/createOrder',
  async (data) => {
    console.log('order data to create', data)
    let response = await axios.post(`${BASE_URL}/api/orders/create`, {
      productId: data,
    })
    return response.data
  }
)

const initialState = {
  cart: null,
  currentCart: null,
  cartOrdered: false,
  isLoading: false,
  isDeleting: false,
  isUpdating: false,
  isOrdering: false,
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCart: (state, action) => {
      state.cart = action.payload
    },
    setCurrentCart: (state, action) => {
      state.currentCart = action.payload
    },
    setCartOrdered: (state, action) => {
      state.cartOrdered = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentCart.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCurrentCart.fulfilled, (state, action) => {
        console.log('this cart:', action.payload)

        state.cart = action.payload
        state.isLoading = false
      })

      //update quantity
      .addCase(updateQuantity.pending, (state) => {
        state.isUpdating = true
      })
      .addCase(updateQuantity.fulfilled, (state, action) => {
        console.log('updated cart product:', action.payload)

        state.cart = action.payload
        state.isUpdating = false
      })

      //delete product
      .addCase(deleteToCart.pending, (state) => {
        state.isDeleting = true
      })
      .addCase(deleteToCart.fulfilled, (state, action) => {
        console.log('phoneOwner', action.payload)

        state.cart = action.payload
        state.isDeleting = false
      })

      //create order
      .addCase(createOrder.pending, (state) => {
        state.isOrdering = true
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        console.log('phoneOwner', action.payload)

        state.cart = null
        state.isOrdering = false
      })
  },
})

export const { setCart, setCurrentCart, setCartOrdered } = cartSlice.actions

export const cartState = (state) => state.cart

export default cartSlice.reducer
