import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const cleanNotificationsChat = async (user, setCleanNotificationChat) => {
  console.log('user : ', user)
  await axios
    .patch(`${BASE_URL}/api/notificationchats/clean/${user}`)
    .then((response) => {
      setCleanNotificationChat(response.data)
    })
}

export { cleanNotificationsChat }
