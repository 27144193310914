import React from 'react'
import axios from 'axios'
import { IoLocation } from 'react-icons/io5'

export default function UserAddress({ location }) {
  const [address, setAddress] = React.useState()
  const getAddress = async () => {
    const res = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${location[0]},${location[1]}.json?access_token=pk.eyJ1IjoidXR5LXdlYiIsImEiOiJjbHN0NTNrdXYxdDJiMmtuMXAxZHA2anM1In0.GRa3DMA8Q2f7x-iRij1ldw`
    )

    console.log('address', res?.data)
    setAddress(res?.data?.features[0]?.place_name)
  }
  React.useEffect(() => {
    getAddress()
  }, [location])

  return (
    <div className="font-light text-[17.5px]  flex items-center gap-1">
      {' '}
      <IoLocation /> {address}
    </div>
  )
}
