import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const getNotificationsChat = async (userId, setNotificationChat) => {
  await axios
    .get(`${BASE_URL}/api/notificationchats/${userId}`)
    .then((response) => {
      setNotificationChat(response.data)
    })
}

export { getNotificationsChat }
