import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetSellerAnnouncementsQuery } from '../../services/announcementApi.js'
import ItemProduct from '../../components/ItemProduct.jsx'
import Nav from '../../components/layout/Nav.jsx'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'
import LottieLoader from '../../components/LottieLoader'

import { FaMoneyBillTrendUp } from 'react-icons/fa6'

function UserAnnouncements() {
  const params = useParams()
  const infoUserRef = React.useRef()
  const { data: announcements, isLoading } = useGetSellerAnnouncementsQuery(
    params.id
  )

  if (isLoading)
    return (
      <div>
        <Nav />
        <div className="flex pt-10 flex-col items-center justify-center sm:h-screen">
          <div>
            <LottieLoader animation={indexLoader} />
          </div>
          <div>Chargement de mes annonces... </div>
        </div>
      </div>
    )

  if (announcements?.length === 0) {
    return (
      <div className="">
        <Nav />
        <div className="flex flex-col gap-5 justify-center items-center pt-40">
          <FaMoneyBillTrendUp className="text-[50px] text-slate-500" />
          <div>{"Pas d'annonces publiés de ce vendeur"}</div>
        </div>
      </div>
    )
  }
  return (
    <div>
      <Nav />
      <div className="pt-36 px-5 md:px-10 ">
        <h5 className="mb-5 text-[20px] font-roboto">
          Les annonces et produits publiés par{' '}
          {announcements[0]?.user?.username}
        </h5>
        <div className="grid grid-cols-2 gap-3 lg:gap-7 md:grid-cols-4 lg:grid-cols-6">
          <ItemProduct
            data={announcements}
            loader={isLoading}
            infoUserRef={infoUserRef}
          />
        </div>
      </div>
    </div>
  )
}

export default UserAnnouncements
