import React from 'react'
import _ from 'lodash'
import { FaCartShopping, FaTruckFast } from 'react-icons/fa6'
import { BsCartCheck } from 'react-icons/bs'
import { NumericFormat } from 'react-number-format'
import { useSelector, useDispatch } from 'react-redux'
import { PhoneNumberModal } from '../PhoneNumberModal'
import { addPhoneNumber } from '../../features/user/user'
import ShipmentCosts from './ShipmentCosts'

function CartSummary({ cart, totalPrice, totalShipFees, toPay, setPaying }) {
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.user.currentUser)
  const loading = useSelector((state) => state.user.loading)
  // const cart = useSelector(state => state.cart.cart)

  const [addingPhone, setAddingPhone] = React.useState(false)
  const [showShips, setShowShips] = React.useState(false)

  const addPhone = React.useCallback(
    async (e, user, phone, setError) => {
      e.preventDefault()
      if (!phone || phone.length < 9) {
        setError(true)
      } else {
        dispatch(addPhoneNumber({ user: user?._id, phone }))
      }
    },
    [dispatch]
  )

  return (
    <aside className="p-4 bg-white rounded-lg shadow-md mb-6">
      <div className="flex gap-5 items-center justify-between mb-2">
        <h2 className="text-xl font-semibold text-gray-800">
          Résumé du panier
        </h2>
        <button
          className="ml-4 text-blue-600 hover:underline focus:outline-none"
          onClick={() => setShowShips(!showShips)}
        >
          {showShips ? 'Masquer les détails' : 'Details livraisons'}
        </button>
      </div>

      <ul className="space-y-4">
        <li className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
          <div className="flex items-center gap-2 text-gray-700">
            <FaCartShopping className="text-xl" />
            <span>Montant total :</span>
          </div>
          <span className="text-lg font-bold text-gray-800 mt-2 sm:mt-0">
            <NumericFormat
              value={_.round(parseInt(totalPrice), -2)}
              displayType={'text'}
              thousandSeparator=" "
              suffix="FC"
            />
          </span>
        </li>
        <li className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
          <div className="flex items-center gap-2 text-gray-700">
            <FaTruckFast className="text-xl" />
            <span>Frais de livraisons :</span>
          </div>
          <div className="flex items-center mt-2 sm:mt-0">
            <span className="text-lg font-bold text-gray-800">
              <NumericFormat
                value={_.round(totalShipFees, -2)}
                displayType={'text'}
                thousandSeparator=" "
                suffix=" FC"
              />
            </span>
          </div>
        </li>
      </ul>

      {showShips && (
        <div className="mt-4">
          <ShipmentCosts
            data={toPay}
            cart={cart}
            user={currentUser}
            openModal={showShips}
            setOpenModal={setShowShips}
          />
        </div>
      )}

      <button
        className="w-full mt-6 flex items-center justify-center gap-2 bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 px-4 rounded-lg transition duration-200"
        onClick={() => setPaying(true)}
      >
        <BsCartCheck className="text-xl" />
        <span>Payer le panier</span>
      </button>

      {addingPhone && (
        <PhoneNumberModal
          openModal={addingPhone}
          setOpenModal={setAddingPhone}
          user={currentUser}
          addPhoneNumber={addPhone}
          dispatch={dispatch}
          loading={loading}
        />
      )}
    </aside>
  )
}

export default CartSummary
