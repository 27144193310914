import React, { useEffect, useState } from 'react'
import UserChat from '../../assets/profil.png'
import { getUser } from '../../services/Users/get-user'
import { getNotificationsConversation } from '../../services/notifications/notificationConversation'
// import { cleanNotificationsConversation } from '../../services/notifications/cleanNotificationConversation'

function Conversation({
  chats,
  currentUser,
  selectedUserIndex,
  onlineUser,
  searchUsers,
  cleanNotifConversation,
}) {
  const [user, setUser] = useState(null)
  const [notificationChat, setNotificationChat] = useState([])

  const userId = chats.members.find((id) => id !== currentUser)
  const userSelected = selectedUserIndex?.members.find(
    (id) => id !== currentUser
  )
  useEffect(() => {
    if (userId) {
      getUser(userId, setUser)
    }
  }, [currentUser, chats])

  const filteredUser = user?.username.toLowerCase().includes(searchUsers)
    ? user
    : null

  const data = searchUsers.length > 0 ? filteredUser : user

  useEffect(() => {
    if (currentUser) {
      getNotificationsConversation(currentUser, setNotificationChat)
    }
  }, [currentUser, cleanNotifConversation])

  return (
    <>
      {data != null && (
        <div
          className={userSelected === userId ? 'bg-[#e9e6e6]' : 'bg-[#fafafa] '}
        >
          <div className="relative flex px-3 py-4 lg:py-3 md:px-4 lg:px-12">
            <div className={`w-[42px] h-[42px] lg:w-[40px] lg:h-[40px] mr-2`}>
              <img
                src={data?.image ? data?.image : UserChat}
                alt="profil user"
                className={`w-[42px] h-[42px] lg:w-[40px] lg:h-[40px] object-cover object-center rounded-full ${onlineUser?.find((user) => user?.userId === userId) && 'border-[3px] border-green-400 p-1'}`}
              />
            </div>
            <div className="flex items-center justify-between w-full">
              <div>
                <div className="text-[15px] lg:text-[14px]">
                  {data?.username}
                </div>
                <div className="text-[11px] font-light">
                  {onlineUser?.find((user) => user.userId === userId) ? (
                    <div className=" text-[11px] font-medium text-green-500">
                      En ligne
                    </div>
                  ) : (
                    <div className=" text-[11px] font-medium">UTY</div>
                  )}
                </div>
              </div>
              {notificationChat?.map((notif, index) => {
                if (notif?.senderId === userId) {
                  return (
                    <div
                      key={index}
                      className={`text-[11px] text-white font-semibold flex tems-center justify-center bg-green-500  ${notif?.message?.length > 9 ? 'px-[3px]' : 'px-[5px]'} rounded-full`}
                    >
                      {notif?.message?.length > 0 && notif?.message?.length}
                    </div>
                  )
                }
              })}
            </div>
          </div>
          <hr className=" opacity-30"></hr>
        </div>
      )}
    </>
  )
}

export default Conversation
